.social-container
{
    display: flex;
    position: fixed;
    flex-direction: column;
    width: 40px;
    height: 99vh;
    left: 3;
    justify-content: flex-end;
    margin-top: 5px;
}

.social-container svg
{
    height: 100%;
    width: 100%;
    fill: var(--primary-color);
    transition: fill 0.2s ease;
    margin: 0;
}

.social-container svg:hover
{
    fill: var(--third-color);
}

.social-container a
{
    padding: 5px;
    display: flex;
    align-items: stretch;
}

.theme-button
{
    margin-bottom: auto !important;
}

.social-container 
{
    opacity: 0;
    animation: FlyRight 0.5s ease, appear 0.5s ease forwards;
    animation-delay: 0.6s !important;
}

@media (max-width: 768px) 
{

    .social-container{
        display: none;
    }

}