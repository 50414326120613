/* Nav Bar */

.top-bar-holder
{
    display: flex;
    justify-content: flex-end;
}

.nav-bar
{
    width: fit-content;
    transition: border-color 0.5s ease, color 0.5s ease-out;
    margin-bottom: 50px;
    margin-top: 50px;
    margin-right: 20px;
}

.nav-bar nav {
    display: flex;
    gap: 30px;
}

.nav-bar a {
    color: var(--primary-color);
    width: 90px;
    text-align: center;
    padding: 14px 5px;
    text-decoration: none;
    font-size: 15px;
    transition: background-color 0.2s ease, color 0.2s ease-out;
    
}

.nav-bar a.active
{
    border-bottom: 1px var(--primary-color) solid;
}

.nav-bar a:first-child
{
    padding-left: 0px;
}

.nav-bar a:hover {
    color: var(--third-color);
}

@media (max-width: 768px)
{
    .top-bar-holder{
        justify-content: center;
        top: 0;
        position: absolute;
        width: 100%;
    }
    .nav-bar
    {
        position: relative;
        margin: 0;
        z-index: 1000;
        border-bottom: 2px solid var(--primary-color);
    }

    .nav-bar a.active
    {
        color: var(--third-color);
        border-bottom: none;
    }
}