#spotify-holder
{
    max-width: 70%;
    opacity: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    margin-right: 15px;
    margin-bottom: 15px;
    animation: flyUp 0.3s ease forwards;
    animation-delay: 0.8s;
}

#song-container p
{
    margin: 0;
    opacity: 1;
    color: var(--primary-color) !important;
    animation: none !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: color 0.3s ease;
}

#song-container
{
    overflow: hidden;
}

#song-name
{
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
}

#song-container a
{
    text-decoration: none;
    color: var(--third-color);
    transition: color 0.3s ease;
}

#album-img
{
    display: none;
    width: auto;
    height: 50px;
    border-radius: 10%;
    border: 2px var(--secondary-color) solid;
    margin-left: 20px;
}

#song-container p
{
    display: inline-flex;
}

#song-container span {
    display: inline-flex; /* Use inline-flex for better alignment */
    align-items: center; /* Vertically centers the items */
}

@keyframes progress-slide
{
    0%
    {
        left: 0
    }
    100%
    {
        left: 99%;
    }
}


@media (max-width: 768px) 
{
    #spotify-holder{
        display: none;
    }
}