/* Styling for the photo page */
#photo-page-container {
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#photo-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid layout */
    gap: 20px;
    margin-top: 20px;
    width: 100%;
}

.photo-card {
    background-color: #fff;
    border-radius: 12px; /* Rounded corners for the photo cards */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.photo-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.photo-image {
    width: 100%;
    height: 100%; 
    object-fit: cover; /* Make sure the image fills the area without distortion */
    border-radius: 12px; /* Apply rounded corners to the image */
}

/* Modal styles for displaying enlarged image */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    position: relative;
    max-width: 90%;  /* Max width set to 90% of the screen */
    max-height: 90%; /* Max height set to 90% of the screen */
    overflow: hidden;
    border-radius: 16px; /* Rounded corners for the modal */
}

.modal-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image is fully contained */
    border-radius: 16px; /* Apply rounded corners inside the modal */
}

/* Close button styling */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    padding: 8px 12px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.close-btn:hover {
    background-color: rgba(255, 255, 255, 0.9);
}

/* Circle close button styling */
.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;  /* Set the size of the circle */
    height: 40px;
    background-color: black;  /* Black background */
    border: none;  /* No border */
    border-radius: 50%;  /* Make it a circle */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0px;  /* Remove any font size because we use the 'X' in the pseudo-element */
    color: white;  /* White color for the 'X' */
    cursor: pointer;
    z-index: 1000;  /* Ensure it's above the modal */
}

.close-btn::before {
    content: "×";  /* Add 'X' symbol */
    font-size: 20px;  /* Adjust the size of 'X' */
    color: white;  /* White color for the 'X' */
    line-height: 1;  /* Prevent extra spacing */
    position: relative;
    top: -1px;  /* Fine-tune vertical positioning */
}

.close-btn:hover {
    background-color: #333;  /* Darker background on hover */
}

.close-btn:focus {
    outline: none;  /* Remove the outline when the button is focused */
}
