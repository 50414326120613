/* theme button.css */

.theme-button
{
    background-color: transparent;
    border: none;
    transition: transform 0.3s ease, border 0.3s ease;
    width: 30px;
    height: 30px;
    margin: 5px;
    padding: 0;
}

.theme-button:hover
{
    transform: scale(1.1);
}

.theme-button:hover #lightmode-img
{
    fill: var(--third-color);
}

#lightmode-img
{
    width: 20px;
    height: auto;
    fill: var(--primary-color);
    transition: fill 0.3s ease;
}

@media (max-width: 768px) 
{
    .theme-button
    {
        display: none; /* only dark mode for mobile */
        position: fixed;
        justify-self: flex-end;
        align-self: flex-end;
        left: 0;
        bottom: 10px;
        padding: 0;
        margin: 10px;;
        width: 40px;
        height: 40px;
    }
}